@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

html {
    position: relative;
    min-height: 100%;
}

body {
    margin-bottom: 96px;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}