@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

// Single side border-radius
@mixin border-radii($topleft, $topright, $bottomright, $bottomleft) {
  border-top-left-radius: $topleft;
  border-top-right-radius: $topright;
  border-bottom-right-radius: $bottomright;
  border-bottom-left-radius: $bottomleft;
  -webkit-border-top-left-radius: $topleft;
  -webkit-border-top-right-radius: $topright;
  -webkit-border-bottom-right-radius: $bottomright;
  -webkit-border-bottom-left-radius: $bottomleft;
  -moz-border-radius-topleft: $topleft;
  -moz-border-radius-topright: $topright;
  -moz-border-radius-bottomright: $bottomright;
  -moz-border-radius-bottomleft: $bottomleft;
}

@mixin box-shadow-xs() {
  box-shadow: 0 1px 3px hsla(0, 0%, 0%, .12), 0 1px 2px hsla(0, 0%, 0%, .24);
}

@mixin box-shadow-sm() {
  box-shadow: 0 3px 6px hsla(0, 0%, 0%, .15), 0 2px 4px hsla(0, 0%, 0%, .12);
}

@mixin box-shadow-md() {
  box-shadow: 0 10px 20px hsla(0, 0%, 0%, .15), 0 3px 6px hsla(0, 0%, 0%, .1);
}

@mixin box-shadow-lg() {
  box-shadow: 0 15px 25px hsla(0, 0%, 0%, .15), 0 5px 10px hsla(0, 0%, 0%, .5);
}

@mixin box-shadow-xl() {
  box-shadow: 0 20px 40px hsla(0, 0%, 0%, .2);
}


#donor-free-entry {
  color: #243A52;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 18px;
    font-weight: 700;
    margin-top: 32px;
  }

  @include border-radius(4px);
  border: 1px solid #DAE2EC;
  background-color: white;
  @include box-shadow-xs;

  label {
    font-size: 16px;
    font-weight: normal;
  }

  .form-input {
    border: none;
    outline: none;
    box-shadow: none;
    width: 100%;

    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }

    font-size: 64px;
  }

  .footer {
    width: 100%;
    padding: 0 24px;
    margin: 8px auto 0 auto;
    border-top: none;
    background-color: white !important;
    text-align: center;

    .btn {
      font-weight: 900;
      padding: 8px 12px;
      min-height: 48px;
      font-size: 20px;
    }

    @media screen and (min-width: 992px) {
      width: 75%;
    }
  }

  .sk-spinner-fading-circle .sk-circle::before {
    background-color: white;
  }

  #donor-box-accessory {
    #accessory-text {
      color: #617D98;

      a {
        color: #617D98;
      }
    }

    color: #324D67;
  }
}

#donor-box {
  @include border-radius(4px);
  border: 1px solid #DAE2EC;

  .header {
    @include border-radii(4px, 4px, 0, 0);
    border-bottom: 1px solid #DAE2EC;
    background-color: #F1F5F8;
    padding: 8px 0;
    text-align: center;

    h2 {
      font-size: 20px;
      font-weight: 600;
      color: #102A42;
      margin-top: 16px;
      margin-bottom: 10px;
    }
  }

  .body {
    position: relative;
    padding: 24px;

    .btn-container {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      justify-content: space-between;
      margin-top: 8px;

      .sk-spinner-fading-circle .sk-circle::before {
        background-color: #617D98;
      }
    }

    .amount-btn {
      color: #617D98;
      background-color: white;
      @include border-radius(4px);
      padding: 16px;
      font-size: 16px;
      font-weight: normal;
      margin: 4px 0;
      cursor: pointer;
      width: calc(((100% - 20px) / 3));
      border: 1px solid #BCCCDC;

      &.two-col-btn {
        width: calc((200% - 14px) / 3);

        &.active {
          padding: 0;
          background-color: transparent;
          border: 0;
        }

        .form-input {
          width: 100%;
          height: 100%;
          color: black;
          @include border-radius(4px);
          border: 1px solid #27AA83;
          padding-left: 16px;
        }
      }

      &.active {
        // Primary colors
        border: 1px solid #27AA83;
        box-shadow: inset 0 0 0 1px #27AA83;
        font-weight: 700;
        color: #27AA83;
      }
    }

    .interval-btn {
      background-color: white;
      border: 1px solid #27AA83;
      color: #27AA83;
      text-decoration: none;
      font-size: 16px;

      &.active {
        background-color: #27AA83;
        border-color: #27AA83;
        color: white;
      }
    }

    .btn-primary {
      color: white;
      margin-top: 8px;
    }
  }

  .footer {
    position: relative;
    padding: 0 24px !important;
    margin-top: 0 !important;
    border-top: none !important;
    background-color: white !important;

    .btn {
      font-weight: 900;
      padding: 8px 12px;
      min-height: 48px;
      font-size: 20px;
      width: 100%;
      background-color: #27AA83;
      color: white;
    }

    .sk-spinner-fading-circle .sk-circle::before {
      background-color: white !important;
    }
  }

  .spin-container {
    margin: 24px auto 0 auto;
  }

  .btn-group-justified {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: separate;
  }

  .btn-group-justified > .btn,
  .btn-group-justified > .btn-group {
    float: none;
    display: table-cell;
    width: 1%;
  }

  .btn-group-justified > .btn-group .btn {
    width: 100%;
  }

  .btn-group-justified > .btn-group .dropdown-menu {
    left: auto;
  }

  .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .btn-group > .btn:last-child:not(:first-child), .btn-group > .dropdown-toggle:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
  }

  .btn.active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  }
}

#donor-box-accessory {
  margin: 12px auto;
  font-size: 12px;
  color: #617D98;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 91%;

  .lock-icon {
    flex-shrink: 0;

    .icon {
      height: 24px;
      width: 24px;
    }
  }

  #accessory-text {
    color: #617D98 !important;

    a {
      text-decoration: underline;
      color: #617D98;
    }
  }
}
