#ocho-rios {
  $yellow: #FACC15;
  $emerald: #047857;
  .prose a {
    color: $yellow;
  }

  .fc-view-container {
    background: white;
  }

  .fc-icon {
    color: $emerald;
  }

  .fc-toolbar {
    color: $emerald;
  }

  .fc-state-highlight {
    border: $yellow;
  }

  .fc-today {
    color: $yellow;
    border-top: 3px solid $yellow !important;
  }

  .fc-state-active {
    background-color: $yellow;
    color: white;
  }
}
