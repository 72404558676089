@import "~fullcalendar/dist/fullcalendar";
@import "~tippy.js/dist/tippy";
@import "~tippy.js/themes/light";

.cal-container {
  position: relative;

  .sk-spinner {
    position: absolute;
    left: 96px;
    top: 4px;
  }
}

/*
This is the Material Design theme for FullCalendar Weekly Agenda view
Creation Date: Aug 19th 2015
Author: Jacky Liang
Version: FullCalendar 2.4.0
Tested Using the Following FC Settings:
    editable: false,
    handleWindowResize: true,
    weekends: false, // Hide weekends
    defaultView: 'agendaWeek', // Only show week view
    header: false, // Hide buttons/titles
    minTime: '07:30:00', // Start time for the calendar
    maxTime: '22:00:00', // End time for the calendar
    columnFormat: {
        week: 'ddd' // Only show day of the week names
    },
    displayEventTime: true,
    allDayText: 'Online/TBD'
Note: This has NOT been tested on Monthly or Daily views.
Colors: Use the following - https://www.google.com/design/spec/style/color.html#color-color-palette
        at the 700 level. An opacity of 0.65 is automatically applied to the
        700 level colors to generate a soft and pleasing look.
        Color were applied to each event using the following code:
        events.push({
            title: 'This is a Material Design event!',
            start: 'someStartDate',
            end: 'someEndDate',
            color: '#C2185B'
        });

        This code has been modified by me, Matt Sencenbaugh to create an alternative for wrestlingiq
*/
/* Remove that awful yellow color and border from today in Schedule */
.fc-state-highlight {
  opacity: 0;
}

.fc-today {
  background: white !important;
  border: none !important;
  font-weight: 900;
}

/* Styling for each event from Schedule */
.fc-time-grid-event.fc-v-event.fc-event {
  border-radius: 4px;
  border: none;
  padding: 4px;
}

/* Semibolds the name of the event and inherits the font size */
.fc-event {
  font-size: 12px;
}
.fc-state-default {
  text-shadow: none;
}

/* Inherits background for each event from Schedule. */
.fc-event .fc-bg {
  z-index: 1 !important;
  background: inherit !important;
  opacity: .25 !important;
}

/* Normal font weight for the time in each event */
.fc-time-grid-event .fc-time {
  font-weight: 500 !important;
}

.fc-day-grid-event .fc-time {
  font-weight: 500 !important;
}

.fc-ltr .fc-h-event.fc-not-end, .fc-rtl .fc-h-event.fc-not-start {
  margin-left: 12px !important;
  padding: 4px !important;
}

/* Apply same opacity to all day events */
.fc-day-grid-event.fc-h-event.fc-event.fc-not-start.fc-end {
  margin-left: 12px !important;
  padding: 4px !important;
}

/* Material design button */
.fc-button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  min-height: 32px;
  min-width: 64px;
  line-height: 32px;
  vertical-align: middle;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center;
  border-radius: 4px;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  border: 0;
  padding: 0 8px;
  margin: 8px;
  letter-spacing: .01em;
  background: transparent;
  color: currentColor;
  white-space: nowrap;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  font-style: inherit;
  font-variant: inherit;
  font-family: inherit;
  text-decoration: none;
  overflow: hidden;
  -webkit-transition: box-shadow .4s cubic-bezier(.25,.8,.25,1),background-color .4s cubic-bezier(.25,.8,.25,1);
  transition: box-shadow .4s cubic-bezier(.25,.8,.25,1),background-color .4s cubic-bezier(.25,.8,.25,1);
}

.fc-left {
  .fc-button {
    min-width: 0;
  }
}

/* Not raised button */
.fc-state-default {
  box-shadow: None;
}

.fc-toolbar .fc-state-active, .fc-toolbar .ui-state-active {
  z-index: 1;
}
